import { SavedSearchesStore } from '@/modules/SavedSearch';
import LocalStorage from '@/modules/LocalStorage';
import customFieldUnits from '@/constants/customFieldUnits';
import { Dilution } from '@/constants/methodology';

export default function createState() {
  const localStore = new LocalStorage('marama', { savedSearches: [] });
  const savedSearches = new SavedSearchesStore(localStore, 'savedSearches');

  return {
    client: null,
    currentUser: null,
    customFieldNames: [],
    customFieldUnits,
    graphQLError: null,
    isLoadingSpectra: false,
    lastDilutionUsedForMethodology: { ...Dilution },
    lastUploadMetadata: null,
    needsRefresh: false,
    selectedMeasures: [],
    spectraForMeasureID: {},
    savedSearches,
    viewClient: null,
  };
}
