/**
 * _Try_ to remove special operators from `inputText` so that it can be used in
 * a RegExp.
 * Note that this should never be used for anything security-related,
 * and that the API should be using passed-in data with great caution
 *
 * @param {string} inputText
 * @returns {string}
 */

export function escapeRegexOperators(inputText) {
  return String(inputText).replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/**
 * Creates a regular expression from a passed string.
 * Any characters that will throw an error in a standard
 * RegExp are replaced
 *
 * @param {string} queryText
 * @returns {RegExp}
 */
export default function buildRegExpFromString(queryText) {
  // Not worried about a DoS on user's own computer!
  /* eslint-disable-next-line security/detect-non-literal-regexp */
  return new RegExp(escapeRegexOperators(queryText), 'i');
}
