import gql from 'graphql-tag';

const defaultFields = `
    error {
        message
        code
    }
    record {
        id
        created
        modified
        client {
            id
            name
            settings
        }
        name
        externalID
    }
    email
    name
    nickname
    picture
    isEmailVerified
    externallyModified
    geoData {
        countryCode
        countryCode3
        countryName
        cityName
        latitude
        longitude
        timeZone
        continentCode
    }
    permissions
    enabledFeatures
`;

export default (fields = defaultFields) => gql`query currentUser {
    currentUser {
        ${fields}
    }
}`;
