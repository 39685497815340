import hydrateMeasure from '@/helpers/hydrateMeasure';
import {
  SET_MARAMA_MARKET_WINES,
  SET_TOTAL_MARAMA_MARKET_WINES,
  SET_MARAMA_MARKET_WINES_SELECTION_FILTER,
  SET_IS_LOADING_MARKET_WINES,
  ADD_SAVED_USER_WINE,
  DELETE_SAVED_USER_WINE,
  DELETE_SAVED_USER_WINE_BY_NAME,
  SET_LOADED_USER_WINES,
} from '../../mutationNames';

export default function createMutations() {
  return {
    [SET_IS_LOADING_MARKET_WINES](state, isLoading) {
      state.isLoadingMarketWines = isLoading;
    },
    // Update ensures each measure in wines is 'hydrated' and contains a
    // link to parent wine under 'benchmark'
    [SET_MARAMA_MARKET_WINES](state, wines) {
      const hydratedWines = wines.map((wine) => ({
        ...wine,
        measures: wine.measures.map((measure) => ({
          ...hydrateMeasure(measure),
          benchmark: wine,
        })),
      }));
      state.maramaMarketWines = hydratedWines;
    },
    [SET_TOTAL_MARAMA_MARKET_WINES](state, total) {
      state.totalMaramaMarketWines = total;
    },
    [SET_MARAMA_MARKET_WINES_SELECTION_FILTER](state, filter) {
      state.maramaMarketWinesSelectionFilter = filter;
    },
    [ADD_SAVED_USER_WINE](state, { params }) {
      const {
        selection, savedUserWinesId, currentClientId, userId,
      } = params;
      const { name: userWineName, items, description } = selection;

      state.savedUserWines.add(
        userWineName,
        items,
        savedUserWinesId,
        currentClientId,
        userId,
        description,
      );
    },
    [SET_LOADED_USER_WINES](state, userWines) {
      state.loadedUserWines = userWines;
    },
    [DELETE_SAVED_USER_WINE](state, id) {
      state.savedUserWines.deleteById(id);
    },
    [DELETE_SAVED_USER_WINE_BY_NAME](state, name) {
      state.savedUserWines.deleteByName(name);
    },
  };
}
