import * as Sentry from '@sentry/vue';

import {
  SET_CLIENT,
  SET_CURRENT_USER,
  SET_ERROR,
  SET_NEEDS_REFRESH,
  SET_VIEWING_CLIENT,
  SET_CUSTOM_FIELD_NAMES,
  SET_LAST_DILUTION_USED_FOR_METHODOLOGY,
  SET_LAST_UPLOAD_METADATA,
  DELETE_SAVED_SEARCH,
  ADD_SAVED_SEARCH,
  UPDATE_SAVED_SEARCH,
  SET_SELECTED_MEASURES,
  SET_SPECTRA,
  CLEAR_MEASURE_SPECTRA,
  SET_IS_LOADING_SPECTRA,
} from '../../mutationNames';

export default function createMutations() {
  return {
    [SET_LAST_UPLOAD_METADATA](state, { metadata }) {
      state.lastUploadMetadata = metadata;
    },
    [SET_CURRENT_USER](state, { user }) {
      state.currentUser = user;
      if (user) {
        Sentry.setUser({ id: `${user?.record?.client?.name}.${user?.record?.externalID}` });
      }
    },
    [SET_CLIENT](state, { client }) {
      state.client = client;

      if (typeof client?.settings === 'string') {
        state.client.settings = JSON.parse(client.settings);
      }
    },
    [SET_VIEWING_CLIENT](state, { client }) {
      state.viewClient = client;
    },
    [SET_ERROR](state, { error }) {
      state.graphQLError = error;
    },
    [SET_CUSTOM_FIELD_NAMES](state, { fieldNames }) {
      state.customFieldNames = fieldNames;
    },
    [SET_NEEDS_REFRESH](state, { needsRefresh = true } = {}) {
      state.needsRefresh = needsRefresh;
    },
    [ADD_SAVED_SEARCH](state, { name, description, search }) {
      state.savedSearches.add(name, search, description);
    },
    [UPDATE_SAVED_SEARCH](state, { name }) {
      state.savedSearches.updateUsage(name);
    },
    [DELETE_SAVED_SEARCH](state, name) {
      state.savedSearches.deleteByName(name);
    },
    [SET_LAST_DILUTION_USED_FOR_METHODOLOGY](state, { methodology, dilution }) {
      state.lastDilutionUsedForMethodology[methodology] = dilution;
    },
    [SET_SELECTED_MEASURES](state, measures) {
      state.selectedMeasures = measures;
    },
    [CLEAR_MEASURE_SPECTRA](state) {
      state.spectraForMeasureID = {};
    },
    [SET_IS_LOADING_SPECTRA](state, isLoading) {
      state.isLoadingSpectra = isLoading;
    },
    [SET_SPECTRA](state, spectra) {
      state.spectraForMeasureID = spectra;
    },
  };
}
