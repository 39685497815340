import VueApollo from 'vue-apollo';
import { BaseError, NetworkError, GraphQLError } from '@/errors';

let provider;

function createErrorFromApolloError(error) {
  if (error?.networkError) {
    return NetworkError.fromApolloError(error);
  } if (error?.graphQLErrors) {
    return GraphQLError.fromApolloError(error);
  }

  return new BaseError(
    error?.gqlError?.message
    || error?.message,
    { ...error },
  );
}

/**
 * By default, all apollo errors cause an error to be thrown from the
 * component. These can be caught by wrapping the child component with
 * ErrorHandler.vue, or you can override by
 * providing a handleGraphQLError method in your component. or an `error`
 * method in the apollo query as per
 * https://apollo.vuejs.org/api/smart-query.html#options
 *
 * @see ApolloErrorHandler in types.d.ts
*/
export default function getApolloProviderInstance({ apolloClient }) {
  if (!provider) {
    provider = new VueApollo({
      defaultClient: apolloClient,
      defaultOptions: {
        $query: {
          error(error, vm, key, type, options) {
            const normalizedError = createErrorFromApolloError(error);

            if (this.handleGraphQLError) {
              /**
               * @type {ApolloErrorHandler}
               */
              return this.handleGraphQLError(normalizedError, vm, key, type, options);
            }

            console.error('GQL error in query: ', normalizedError, vm, key, type, options);

            this.$nextTick(() => {
              if (normalizedError instanceof NetworkError && normalizedError.isNotLoggedIn()) {
                this.$auth.loginWithRedirect();
                return;
              }
              throw normalizedError;
            });

            return true;
          },
        },
      },
    });
  }

  return provider;
}
