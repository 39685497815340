import gql from 'graphql-tag';
import error from './partials/error';
import measureForList from './partials/measureForList';
import node from './partials/node';
import cielabData from './partials/cielabData';
import wavelengthData from './partials/wavelengthData';

// eslint-disable-next-line import/prefer-default-export
const defaultFields = `
  ${node}
  ${measureForList}
  ${cielabData}
  ${wavelengthData}
  ${error}
`;

const defaultSearchInfoFields = `
    year
    grape
    batch(limit: 10)
    vessel(limit: 10)
    fieldName
    methodology
    processStage
    region
    vineyard
`;

export default (fields = defaultFields, queryName = 'measurements') => gql`query ${queryName}(
  $filter: MeasureFilter,
  $first: Int,
  $offset: Int = 0,
  $sortBy: String,
  $sortDesc: Boolean = false
) {
  measurements(
    filter: $filter,
    paging: {
      first: $first,
      offset: $offset,
      sortBy: $sortBy,
      sortDesc: $sortDesc
    }
  ) {
    __typename

    totalCount

    pageInfo {
      # endCursor
      hasNextPage
      hasPreviousPage
      # startCursor
    }

    nodes {
      ${fields}
    }
  }

  searchInfo(filter: $filter) {
      __typename

      ...on SearchInfo {
          ${defaultSearchInfoFields}
      }
      ...on Error {
          message
      }
  }
}`;
