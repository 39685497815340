export default {
  AWRI: 'awri',
  BENCHMARKS: 'benchmarks',
  CIELAB: 'cielab',
  GRAPH_COMPARE: 'graph-compare',
  GRAPH_SPECTRA: 'graph-spectra',
  GRAPH_TIMELINE: 'graph-timeline',
  SAVED_SEARCHES: 'saved-searches',
  UPLOAD_CSVS: 'upload-csvs',
  UPLOAD_ZIPS: 'upload-zips',
  REPORTING: 'reporting',
  REPORT_PROCESS_STAGE_COUNTS: 'report-process-stage-counts',
  REPORT_FIELD_COUNTS: 'report-field-counts',
  MARKET_VIEW: 'market-view',
};
