import appFeatures from '@/constants/appFeatures';
import hasFeature, { doesFeatureExist } from '@/helpers/hasFeature';
import { getTableWavelengthsFromEnv } from '@/app/parseEnv';
import { mapArrayToObject } from '@/helpers/objectMap';

export default function createGetters() {
  return {
    tableWavelengths(/* store */) {
      return getTableWavelengthsFromEnv();
    },

    hasFeature({ currentUser }) {
      return (featureName) => {
        if (!doesFeatureExist(featureName)) {
          throw new Error(`Undefined feature ${featureName} is not recognized`);
        }

        const userFeatures = mapArrayToObject(currentUser?.enabledFeatures || [],
          (enabledFeatureName) => [enabledFeatureName, true]);

        return hasFeature(featureName, userFeatures);
      };
    },

    enabledFeatures({ currentUser }) {
      return currentUser?.enabledFeatures;
    },

    AppFeatures() {
      return appFeatures;
    },

    currentClient({ client, viewClient }) {
      return viewClient || client;
    },

    selectedMeasuresWithAbsorption({ selectedMeasures, spectraForMeasureID }) {
      return selectedMeasures
        .map((measure) => ({
          ...measure,
          ...(spectraForMeasureID[measure.id] || {}),
          cielab: spectraForMeasureID[measure.id]?.cielab || measure.cielab,
          data: {
            ...measure.data,
            ...(spectraForMeasureID[measure.id]?.data || {}),
          },
        }))
        .filter((m) => m.data?.absorptionOD?.length);
    },
  };
}
