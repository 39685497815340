/* eslint-disable no-console */

import { register } from 'register-service-worker';
import { getBaseURLFromEnv, getNodeFromEnv } from './app/parseEnv';

if (getNodeFromEnv() === 'production') {
  register(`${getBaseURLFromEnv()}service-worker.js`, {
    error(error) {
      console.error('Error during service worker registration:', error.message);
    },
  });
}
