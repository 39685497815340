import createMutations from './mutations';
import createActions from './actions';
import createGetters from './getters';
import createState from './state';

export default function (apollo) {
  return {
    namespaced: true,
    state: createState(),
    mutations: createMutations(),
    actions: createActions({ apollo }),
    getters: createGetters(),
  };
}
