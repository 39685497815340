export default function createDashboardRoutes(path = '/dashboard/:request?', { beforeEnter }) {
  return [{
    path,
    name: 'Dash',
    beforeEnter,
    props: {
      default: true,
    },
    meta: {
      title: 'Dashboard',
    },
    components: {
      appbar: () => import(/* webpackChunkName: "topnav" */ '../../components/AppBar.vue'),
      appbar_extension: () => import('../../components/AppBarTabs.vue'),
      default: () => import(/* webpackChunkName: "newdash" */ '../../views/VeritasDash.vue'),
    },
  }];
}
