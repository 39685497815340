// GENERAL STORE
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_FIELD_NAMES = 'GET_FIELD_NAMES';
export const SET_VIEWING_CLIENT = 'SET_VIEWING_CLIENT';
export const REFRESH_FEATURES = 'REFRESH_FEATURES';
export const GET_MEASURES_BY_ID = 'GET_MEASURES_BY_ID';
export const GET_AWRI_RESULTS = 'GET_AWRI_RESULTS';
export const SET_MEASURE_SELECTION = 'SET_MEASURE_SELECTION';
export const FETCH_MEASURE_SPECTRA = 'FETCH_MEASURE_SPECTRA';

// MARKET WINES STORE
export const FETCH_MARAMA_MARKET_WINES = 'FETCH_MARAMA_MARKET_WINES';
export const GET_MARKET_WINES_SELECTION_FILTER = 'GET_MARKET_WINES_SELECTION_FILTER';

export const UPDATE_LOADED_USER_WINES = 'UPDATE_LOADED_USER_WINES';
export const SAVE_USER_WINE = 'SAVE_USER_WINE';
export const DELETE_USER_WINES = 'DELETE_USER_WINES';
export const GET_USER_WINE_BY_MEASURE_ID = 'GET_USER_WINE_BY_MEASURE_ID';
export const MIGRATE_USER_WINES = 'MIGRATE_USER_WINES';
