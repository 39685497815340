class ProcessStage {
  constructor({
    recommendedBaseFields = [],
    recommendedCustomFields = [],
    name,
    label,
    requiredFields = [],
    sortOrder = -1,
  }) {
    this.name = name;
    this.label = label;
    this.requiredFields = requiredFields;
    this.recommendedBaseFields = [
      'region',
      'vessel',
      'vineyard',
    ].concat(recommendedBaseFields || []);
    this.recommendedCustomFields = [
      'Temperature',
    ].concat(recommendedCustomFields || []);
    this.sortOrder = sortOrder;
  }

  isFieldRequired(f) { return this.requiredFields.indexOf(f) >= 0; }

  isFieldUsed(f) { return this.isFieldRequired(f) || this.isFieldRecommended(f); }

  isFieldRecommended(f) {
    return this.recommendedBaseFields.indexOf(f) >= 0
      || this.recommendedCustomFields.indexOf(f) >= 0;
  }
}

export const Stages = [
  {
    label: 'Grape',
    name: 'grape',
    recommendedCustomFields: ['Berry methodology', 'Berry weight'],
    requiredFields: ['region', 'vineyard'],
    sortOrder: 10,
  },
  { label: 'Juice at Intake', name: 'juice_at_intake', sortOrder: 20 },
  { label: 'Clarified Juice', name: 'clarified_juice', sortOrder: 30 },
  {
    label: 'Press Cut',
    name: 'press_cut',
    recommendedCustomFields: ['Press cut stage'],
    sortOrder: 40,
  },
  {
    label: 'Juice/Must Pre Soak',
    name: 'juice_must_pre_soak',
    sortOrder: 50,
  },
  {
    label: 'Juice/Must Post Soak',
    name: 'juice_must_post_soak',
    recommendedCustomFields: ['Brix'],
    sortOrder: 60,
  },
  {
    label: 'Primary Ferment',
    name: 'primary_ferment',
    recommendedCustomFields: ['Brix'],
    requiredFields: ['processStageDay'],
    sortOrder: 70,
  },
  {
    label: 'Post ferment',
    name: 'post_ferment',
    sortOrder: 80,
  },
  {
    label: 'Extended Maceration',
    name: 'extended_maceration',
    recommendedCustomFields: ['Brix'],
    requiredFields: ['processStageDay'],
    sortOrder: 90,
  },
  {
    label: 'Post Press',
    name: 'post_press',
    sortOrder: 100,
  },
  {
    label: 'In Cellar',
    name: 'in_cellar',
    sortOrder: 110,
  },
  {
    label: 'In Barrels',
    name: 'in_barrels',
    sortOrder: 120,
  },
  {
    label: 'In Stainless',
    name: 'in_stainless',
    sortOrder: 130,
  },
  {
    label: 'Malo-Lactic Fermentation',
    name: 'malo_lactic_fermentation',
    sortOrder: 140,
  },
  {
    label: 'Post Malo-Lactic Fermentation',
    name: 'post_malo_lactic_fermentation',
    sortOrder: 150,
  },
  {
    label: 'On Lees - Not Sulphured',
    name: 'on_lees_not_sulphured',
    sortOrder: 160,
  },
  {
    label: 'On Lees - Sulphured',
    name: 'on_lees_sulphured',
    recommendedCustomFields: ['Sulphur concentration'],
    sortOrder: 170,
  },
  {
    label: 'Sulphured',
    name: 'sulphured',
    recommendedCustomFields: ['Sulphur concentration'],
    sortOrder: 180,
  },
  {
    label: 'Racked Sulphured',
    name: 'racked_sulphured',
    sortOrder: 190,
  },
  {
    label: 'Pre-Fining', name: 'pre_fining', recommendedCustomFields: ['Fining method'], sortOrder: 200,
  },
  {
    label: 'Post-Fining', name: 'post_fining', recommendedCustomFields: ['Fining method'], sortOrder: 210,
  },
  {
    label: 'Pre-Filtering', name: 'pre_filtering', recommendedCustomFields: ['Fining method'], sortOrder: 220,
  },
  {
    label: 'Post-Filtering', name: 'post_filtering', recommendedCustomFields: ['Fining method'], sortOrder: 230,
  },
  {
    label: 'Bottled', name: 'bottled', recommendedCustomFields: ['Fining method'], sortOrder: 240,
  },
].map((metadata) => new ProcessStage(metadata));

export const ValidStageNames = Stages.map(({ name }) => name);

export const StagesMap = new Map(Stages.map((stage) => [stage.name, stage]));

export const StageNames = {
  GRAPE: 'grape',
  JUICE_AT_INTAKE: 'juice_at_intake',
  CLARIFIED_JUICE: 'clarified_juice',
  PRESS_CUT: 'press_cut',
  JUICE_MUST_PRE_SOAK: 'juice_must_pre_soak',
  JUICE_MUST_POST_SOAK: 'juice_must_post_soak',
  PRIMARY_FERMENT: 'primary_ferment',
  POST_FERMENT: 'post_ferment',
  EXTENDED_MACERATION: 'extended_maceration',
  POST_PRESS: 'post_press',
  IN_CELLAR: 'in_cellar',
  IN_BARRELS: 'in_barrels',
  IN_STAINLESS: 'in_stainless',
  MALO_LACTIC_FERMENTATION: 'malo_lactic_fermentation',
  POST_MALO_LACTIC_FERMENTATION: 'post_malo_lactic_fermentation',
  ON_LEES_NOT_SULPHURED: 'on_lees_not_sulphured',
  ON_LEES_SULPHURED: 'on_lees_sulphured',
  SULPHURED: 'sulphured',
  RACKED_SULPHURED: 'racked_sulphured',
  PRE_FINING: 'pre_fining',
  POST_FINING: 'post_fining',
  PRE_FILTERING: 'pre_filtering',
  POST_FILTERING: 'post_filtering',
  BOTTLED: 'bottled',
};

export function getProcessStageByLabel(label) {
  return Stages.find((stage) => stage.label === label);
}
export function getProcessStageMetadata(processStage) {
  if (!StagesMap.has(processStage)) {
    // if stage is unknown return a default
    return new ProcessStage({
      name: processStage,
      label: processStage,
      sortOrder: -1,
    });
  }
  return StagesMap.get(processStage);
}

export function orderByProcessStage(stageA, stageB) {
  const indexA = getProcessStageMetadata(stageA).sortOrder;
  const indexB = getProcessStageMetadata(stageB).sortOrder;
  return indexA - indexB;
}

export function orderByProcessStageLabel(labelA, labelB) {
  const stageA = getProcessStageByLabel(labelA);
  const stageB = getProcessStageByLabel(labelB);
  return stageA.sortOrder - stageB.sortOrder;
}
