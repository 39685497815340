<template>
  <div class="tag-list">
    <template v-if="editable">
      <tags-field
        :value="tags"
        :label="label"
        @input="$emit('update:tags', $event)"
        :editable="true"
        v-bind="$attrs"
      />
    </template>
    <v-input :label="tags.length ? label : undefined" v-else>
      <div class="d-flex flex-wrap">
        <slot name="tag" :tag="tag" v-for="tag in tags" :props="tagProps">
          <v-chip
            :key="tag"
            label
            small
            :ripple="false"
            :class="{ 'ma-1': true, 'taglist--nolabel': !label }"
            >{{ tag }}</v-chip
          >
        </slot>
      </div>
    </v-input>
  </div>
</template>

<script>
import TagsField from '@/components/TagsField.vue';

export default {
  name: 'TagList',
  emits: ['update:tags'],
  components: {
    TagsField,
  },
  props: {
    label: {
      type: String,
      default: 'Labels',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    tags() {
      return [...new Set(this.value)];
    },
    tagProps() {
      return {
        label: true,
        small: true,
        ripple: false,
        class: 'ma-1',
      };
    },
  },
};
</script>

<style lang="scss">
.taglist--nolabel:first-child {
  margin-left: 0 !important;
}
</style>
