import gql from 'graphql-tag';
import error from './partials/error';
import measureData from './partials/measureData';
import node from './partials/node';
import cielabData from './partials/cielabData';

// eslint-disable-next-line import/prefer-default-export
const defaultFields = `
  ${node}
  ${cielabData}
  ${measureData}
  ${error}
`;

export default (fields = defaultFields, queryName = 'measurements') => gql`query ${queryName}(
  $filter: MeasureFilter,
  $first: Int,
  $offset: Int = 0,
  $sortBy: String,
  $sortDesc: Boolean = false
) {
  measurements(
    filter: $filter,
    paging: {
      first: $first,
      offset: $offset,
      sortBy: $sortBy,
      sortDesc: $sortDesc
    }
  ) {
    __typename

    totalCount

    pageInfo {
      # endCursor
      hasNextPage
      hasPreviousPage
      # startCursor
    }

    nodes {
      ${fields}
    }

  }
}`;
