import { getTags } from '@/graphql/queries';

export default {
  data: () => ({
    tags: { nodes: [], pageInfo: {}, total: 0 },
  }),
  created() {
    if (!('currentSearch' in this)) {
      throw new Error('apiTags expects mixin target to have "currentSearch" property');
    }
  },
  apollo: {
    tags: {
      query: getTags,
      debounce: 200,
      variables() {
        return {
          filter: this.search || undefined,
          first: 10,
        };
      },
      update: ({ tags }) => tags,
    },
  },
};
