import { ApolloClient } from 'apollo-client';
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { getApiUrlsFromEnv } from '@/app/parseEnv';
import { getInstance as getAuth0Instance } from '@/plugins/auth0';
import introspectionQueryResultData from '@/graphql/fragmentTypes.json';
import getSentryInstance from './sentry';
import { NetworkError } from './errors';

let defaultApolloClient;

// In test mode we need to fake this because otherwise we get error:
//   Invariant Violation: fetch is not found globally and no fetcher passed, to fix pass a fetch for
const fetchOptions = process.env.NODE_ENV === 'test'
  ? { fetch() { } }
  : undefined;

function setupHttpLink(graphqlUri, clientInfo) {
  return createHttpLink({
    // You should use an absolute URL here
    uri() {
      const currentMaramalabsClient = clientInfo.getCurrentClientID();

      return currentMaramalabsClient
        ? `${graphqlUri}?clientID=${currentMaramalabsClient}`
        : graphqlUri;
    },
    fetch: fetchOptions?.fetch,
  });
}
function setupAuthLink() {
  return setContext(async () => {
    const instance = getAuth0Instance();
    const token = await instance.getTokenSilently();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });
}

/**
 * Handle errors sent from the GraphQL layer.
 * @todo -- we need to get these errors handled in the App, and not just in the console.
 */
function graphQLErrorHandler({ graphQLErrors, networkError }) {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => console.error(
      `[errorLink gQL]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    ));
    getSentryInstance().captureException(
      new global.AggregateError(graphQLErrors, 'The server responded with an error, please try again', {
        details: {
          ...graphQLErrors[0].details,
          locations: graphQLErrors[0].locations,
          path: graphQLErrors[0].path,
        },
        stack: graphQLErrors[0].stack,
      }),
    );
  }
  if (networkError) {
    console.error(`[errorLink Network]: ${networkError}`);
    getSentryInstance().captureException(NetworkError.fromError(networkError));
  }
}

function setupErrorLink() {
  return onError(graphQLErrorHandler);
}

function setupCache() {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  // Cache implementation
  const cache = new InMemoryCache({
    fragmentMatcher,
  });
  return cache;
}

export default function getApolloClientInstance({ clientInfo }) {
  if (!defaultApolloClient) {
    const { graphql: graphqlUri } = getApiUrlsFromEnv();

    /** HTTP connection to the API */
    const httpLink = setupHttpLink(graphqlUri, clientInfo);
    /** Authentication apollo middleware */
    const authLink = setupAuthLink();
    /** Error handling apollo middleware */
    const errorLink = setupErrorLink();

    const cache = setupCache();

    // Create the apollo client
    defaultApolloClient = new ApolloClient({
      link: errorLink.concat(authLink).concat(httpLink),
      cache,
      fetchOptions,
      connectToDevTools: true,
    });
  }
  return defaultApolloClient;
}
