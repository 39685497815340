import Vue from 'vue';
import Vuetify, {
  VIcon, VBtn, VSelect, VSpacer, VExpandTransition, VList, VListItem,
  VContainer, VSimpleTable, Ripple,
} from 'vuetify/lib';

Vue.use(Vuetify, {
  components: {
    /* The following are required in order to use them in functional components */
    VBtn,
    VIcon,
    VSelect,
    VSpacer,
    VExpandTransition,
    VList,
    VListItem,
    VContainer,
    VSimpleTable,
  },
  directives: {
    Ripple,
  },
});

export default new Vuetify({
});
