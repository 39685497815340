import { getOwnProperty } from '@/helpers/hasOwnProperty';

const Methodology = {
  COLOR: 'COLOR',
  PHENOLICS: 'PHENOLICS',
  AWRI_ACID: 'AWRI_ACID',
  AWRI_BLEACH: 'AWRI_BLEACH',
};

const MethodologyLabels = {
  COLOR: 'Color',
  PHENOLICS: 'Phenolics',
  AWRI_ACID: 'AWRI Acidification',
  AWRI_BLEACH: 'AWRI Sulfite Bleaching',
};

const MethodologyAbbreviations = {
  COLOR: 'C',
  PHENOLICS: 'P',
  AWRI_ACID: 'A',
  AWRI_BLEACH: 'B',
};

/**
 * Valid values for the `methodology` field of Measures
 */
export default Methodology;

export const Dilution = {
  COLOR: 1,
  PHENOLICS: 100,
  AWRI_ACID: 100,
  AWRI_BLEACH: 10,
};

export function getDefaultDilutionForMethodology(methodology) {
  return getOwnProperty(Dilution, methodology);
}

export function getMethodologyLabel(methodology) {
  return getOwnProperty(MethodologyLabels, methodology);
}

export function getMethodologyAbbreviation(methodology) {
  return getOwnProperty(MethodologyAbbreviations, methodology);
}
