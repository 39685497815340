// GENERAL STORE
export const SET_CLIENT = 'SET_CLIENT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CUSTOM_FIELD_NAMES = 'SET_CUSTOM_FIELD_NAMES';
export const SET_ERROR = 'SET_ERROR';
export const SET_NEEDS_REFRESH = 'SET_NEEDS_REFRESH';
export const SET_VIEWING_CLIENT = 'SET_VIEWING_CLIENT';
export const SET_LAST_DILUTION_USED_FOR_METHODOLOGY = 'SET_LAST_DILUTION_USED_FOR_METHODOLOGY';
export const SET_LAST_UPLOAD_METADATA = 'SET_LAST_UPLOAD_METADATA';
export const ADD_SAVED_SEARCH = 'ADD_SAVED_SEARCH';
export const DELETE_SAVED_SEARCH = 'DELETE_SAVED_SEARCH';
export const UPDATE_SAVED_SEARCH = 'UPDATE_SAVED_SEARCH';
export const SET_SELECTED_MEASURES = 'SET_SELECTED_MEASURES';
export const SET_SPECTRA = 'SET_SPECTRA';
export const CLEAR_MEASURE_SPECTRA = 'CLEAR_MEASURE_SPECTRA';
export const SET_IS_LOADING_SPECTRA = 'SET_IS_LOADING_SPECTRA';

// MARKET WINES STORE
export const SET_MARAMA_MARKET_WINES = 'SET_MARAMA_MARKET_WINES';
export const SET_TOTAL_MARAMA_MARKET_WINES = 'SET_TOTAL_MARAMA_MARKET_WINES';
export const SET_MARAMA_MARKET_WINES_SELECTION_FILTER = 'SET_MARAMA_MARKET_WINES_SELECTION_FILTER';
export const SET_IS_LOADING_MARKET_WINES = 'SET_IS_LOADING_MARKET_WINES';

export const ADD_SAVED_USER_WINE = 'ADD_SAVED_USER_WINE';
export const DELETE_SAVED_USER_WINE = 'DELETE_SAVED_USER_WINE';
export const DELETE_SAVED_USER_WINE_BY_NAME = 'DELETE_SAVED_USER_WINE_BY_NAME';
export const SET_LOADED_USER_WINES = 'SET_LOADED_USER_WINES';
