import gql from 'graphql-tag';

export default gql`
query AWRIAnalyses($measures: [ID]!) {
  analyses(measures: $measures) {
    ...on Error {
      error: message
      code
    }

    ...on AdvancedAnalyses {
      awri {
        ...on Error {
          error: message
          reference
        }
        ...on AWRIDetail {
          analysis {
            freeAnthocyanin
            pigmentedTannin
            pigmentedTanninPercent
            totalPigments
            totalTannin
            totalPhenolics
          }
          methodologies
          measures
        }
      }
    }
  }
}`;
