export default async function digestItemIds(itemIds) {
  if (itemIds.length <= 0) {
    throw new Error('No items to be digested');
  }
  if (itemIds.includes(undefined)) {
    throw new Error(`Some items to be digested are undefined: ${itemIds}`);
  }

  // sort to ensure hash is same for any same set of ids
  const itemIdsUint8 = new TextEncoder().encode(itemIds.toSorted()); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-1', itemIdsUint8); // hash the message
  const hashInBytes = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashInBytes
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
}
