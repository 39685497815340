export default `
  ... on Measure {
    id
    externalID

    fields {
      name
      value
    }

    analyses {
      wavelengths {
        entries {
          key
          value
        }
      }
    }

    data {
      absorptionOD
      wavelengths
    }
  }
`;
