import { dotGet, dotSet } from '@/helpers/dotgetset';

export default class LocalStorage {
  constructor(storageKey, initialValue = {}) {
    this.storageKey = storageKey;
    this.initialValue = initialValue;
    this.parsed = initialValue;
    this.read();
    this.write();
  }

  read(subPath) {
    try {
      this.parsed = JSON.parse((window.localStorage[this.storageKey] || 'false')) || this.initialValue;
    } catch {
      this.parsed = this.initialValue;
    }
    return subPath ? dotGet(this.parsed, subPath) : this.parsed;
  }

  write(replacementData) {
    this.parsed = replacementData || this.parsed;
    window.localStorage[this.storageKey] = JSON.stringify(this.parsed);
  }

  update(keyPath, value) {
    this.read();
    dotSet(this.parsed, keyPath, value);
    this.write();
  }
}
