import Vue from 'vue';
import VueRouter from 'vue-router';

let hasBeenMonkeyPatched = false;

/**
 * Based on code from here:
 * https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 *
 * @returns VueRouter
 */
export default function patchVueRouter() {
  if (hasBeenMonkeyPatched) {
    return VueRouter;
  }

  const originalPush = VueRouter.prototype.push;

  VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalPush.call(this, location, onResolve, onReject);
    }

    return originalPush
      .call(this, location)
      .catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
          // resolve err
          return err;
        }

        // rethrow error
        return Promise.reject(err);
      });
  };

  hasBeenMonkeyPatched = true;

  Vue.use(VueRouter);

  return VueRouter;
}
