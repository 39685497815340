/**
 * Helper to get current date/time, but this allows for overriding date.now for
 * testing purposes
 */
let now;

export function setNowDate(newValue) {
  const oldValue = now;

  if (!(newValue instanceof Date)) {
    throw new Error('setNowDate expects a Date argument');
  }

  now = newValue;

  return () => setNowDate(oldValue);
}

export default function nowAsTimestamp() {
  if (typeof now === 'undefined') {
    return Date.now();
  }
  return now.valueOf();
}

export function nowAsDate() {
  return typeof now === 'undefined' ? new Date() : now;
}
