export function calcVhSize() {
  // eslint-disable-next-line no-shadow
  const vh = window.innerHeight * 0.01; // 1% of height
  // we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  return vh;
}

// return CSS to use the --vh var
export function vh(n) {
  return `calc( var( --vh, 1vh ) * ${n} )`;
}

//
// So if the browser height is 600px then we’ll now have a value of 6 to work with.
// We can then pass this variable into our CSS:
//
// .slide {
// height: 100vh; /* Fallback */
// height: calc(var(--vh, 1vh) * 100);
// }

export default calcVhSize();
