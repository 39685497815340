<template>
  <v-app>

    <keep-alive>
      <router-view name="appbar" :show-search-bar.sync="showSearchBar">
        <router-view name="appbar_extension"/>
      </router-view>
    </keep-alive>

    <keep-alive>
      <router-view :show-search-bar.sync="showSearchBar" />
    </keep-alive>

    <router-view name="footer" />
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    showSearchBar: false,
  }),
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: relative;
}

.dialogs-wrapper {
  > * {
    position: absolute;
    left: 50vw;
    top: 50vh;
    min-width: 30vw;
    box-sizing: border-box;
    background: white;
    transform: translate(-50%, -50%);
    box-shadow: 0 $_tiny $_lg -$_med rgba(0, 0, 0, 0.5);
    border-radius: $_radius;
  }
  z-index: 1000;
}
</style>
