export default function createMarketRoutes(path = '/market/:request?', { beforeEnter }) {
  return [{
    path,
    name: 'Market',
    beforeEnter,
    props: {
      default: true,
      appbar: {
        showSearchToggle: false,
        showSearchBar: false,
      },
    },
    meta: {
      title: 'Market Wines',
    },
    components: {
      appbar: () => import(/* webpackChunkName: "topnav" */ '../../components/AppBar.vue'),
      appbar_extension: () => import('../../components/AppBarTabs.vue'),
      default: () => import(/* webpackChunkName: "newdash" */ '../../views/market_wines/MarketWinesView.vue'),
    },
  }];
}
