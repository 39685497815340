/* eslint-disable max-classes-per-file */
import getSearchDescription from '@/helpers/getSearchDescription';
import { SavedItems, SavedItemsStore } from './SavedItems';

export class SavedSearch extends SavedItems {
  constructor(name, search, id, clientId, userId, {
    description, created, lastUsed, useCount = 0, isFavorite = false,
  } = {}) {
    super(name, search, id, clientId, userId, {
      created, lastUsed, useCount, isFavorite,
    });
    this.search = this.items;
    this.description = description || getSearchDescription(search);
  }

  static fromJSON(jsObject) {
    return new SavedSearch(
      jsObject.name,
      jsObject.items || jsObject.search,
      jsObject.id,
      jsObject.clientId,
      jsObject.userId,
      jsObject,
    );
  }
}

export class SavedSearchesStore extends SavedItemsStore {
  _load() {
    this.items = this.storage.read(this.subpath).map(SavedSearch.fromJSON);
  }

  add(name, search, description) {
    this.items.push(new SavedSearch(name, search, { description }));
    this._save();
  }
}
