import { getProcessStageMetadata } from '@/constants/processStages';
import { BadArguments } from '@/errors';
import dayjs from '@/helpers/dayjs';

/**
 * 'Hydrate' a measure coming from the server.
 * This, for now, means making sure dates are dayjs instances,
 * converted to localTime from UTC
 *
 * @param {VeritasMeasure} measure Measure data from server as per GraphQL schemas
 */
export default function hydrateMeasure(measure) {
  if (!measure) {
    throw new BadArguments('Empty measurement given to hydrateMeasure');
  }

  if (dayjs.isDayjs(measure.measuredOn)) {
    throw new Error('hydrateMeasure called twice!!');
  }
  const out = {
    ...(measure || {}),
    measuredOn: dayjs.isDayjs(measure?.measuredOn)
      ? measure.measuredOn
      : measure?.measuredOn
      && dayjs.utc(measure.measuredOn).local(),
  };

  if (out.metadata) {
    out.metadata.measuredOn = dayjs.utc(out.metadata.measuredOn).local();
  }
  if (out.modified) {
    out.modified = dayjs.utc(out.modified).local();
    out.created = dayjs.utc(out.created).local();
  }

  out.processStageMetadata = getProcessStageMetadata(out.processStage);
  return out;
}
