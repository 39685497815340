export default function formatBytes(n, { decimalPlaces = 2 } = {}) {
  const suffices = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB'];
  const pow = Math.floor(Math.log2(n) / 10);
  const count = n / 2 ** (pow * 10);
  const isFractional = Math.floor(count) !== count;

  return {
    text: `${isFractional ? count.toFixed(decimalPlaces) : count} ${suffices[pow]}`,
    value: count,
    suffix: suffices[pow],
    bytes: n,
    power: 10 * pow,
  };
}

export function niceBytes(n) {
  return formatBytes(n).text;
}
