export default `
  ... on Measure {
    id
    externalID

    analyses {
      cielab {
        lab
        rgb
        rgbHex
      }
    }
  }
`;
