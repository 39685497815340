import VueApollo from 'vue-apollo';
import VueFileAgent from 'vue-file-agent';

import MomentPlugin from '@maramalabs/vue-plugin-moment';

import { getAuthConfigFromEnv } from '@/app/parseEnv';
import { Auth0Plugin } from '@/plugins/auth0';
import formatBytes from '@/helpers/formatBytes';

import TagList from '@/components/TagList.vue';
import { forEachKey } from '@/helpers/objectMap';

export function registerGlobalComponents(vue) {
  vue.component('tag-list', TagList);
}

export function registerPlugins(vue, router) {
  vue.use(VueApollo);
  vue.use(VueFileAgent);

  // Install the authentication plugin here
  vue.use(Auth0Plugin, {
    ...getAuthConfigFromEnv(),
    onRedirectCallback: (appState) => {
      const fallbackLocation = /^\/?$/.test(window.location.pathname)
        ? '/dashboard'
        : window.location.pathname;
      router.push(
        appState?.targetUrl || fallbackLocation,
      );
    },
  });

  vue.use(MomentPlugin);
}

export function registerFilters(vue) {
  const filters = {
    moment: (value, format) => vue.prototype.$moment(value, format),
    bytes: (value, field = 'text', options) => (formatBytes(value, options)[field]),
    shortDate: (value) => vue.prototype.$moment(value, 'll'),
    longDate: (value) => vue.prototype.$moment(value, 'longdate'),
    ago: (value) => vue.prototype.$moment(value, 'ago'),
  };

  // setup filters for use in templates
  forEachKey(filters, (name, callback) => {
    vue.filter(name, callback);
  });

  vue.use({
    install(vueObject /* options */) {
      // Ensure we can access filters also from scripts via this.$filters[name]
      // eslint-disable-next-line no-param-reassign
      vueObject.prototype.$filters = filters;
    },
  });
}
