import gql from 'graphql-tag';

export default gql`query fieldNames {
  searchInfo {
    __typename

    ...on SearchInfo {
      fieldName
    }
  }
}`;
