import Methodology from '@/constants/methodology';
import {
  getCurrentUser,
  getFieldNames,
  getMeasuresByID as graphqlGetMeasuresByID,
} from '@/graphql/queries';
import getCurrentUserFeatures from '@/graphql/queries/getCurrentUserFeatures';
import getAwriResults from '@/graphql/queries/getAwriResults';

import getSelectedData from '@/graphql/queries/getSelectedData';
import { mapArrayToObject } from '@/helpers/objectMap';
import {
  GET_CURRENT_USER,
  GET_FIELD_NAMES,
  REFRESH_FEATURES,
  SET_VIEWING_CLIENT,
  SET_MEASURE_SELECTION,
  FETCH_MEASURE_SPECTRA,
} from '@/store/actionNames';

import {
  SET_CLIENT,
  SET_CURRENT_USER,
  SET_CUSTOM_FIELD_NAMES,
  SET_VIEWING_CLIENT as MUTATION_SET_VIEWING_CLIENT,
  SET_SELECTED_MEASURES,
  SET_SPECTRA,
  SET_IS_LOADING_SPECTRA,
} from '@/store/mutationNames';

export default function createActions({ apollo }) {
  return {
    async [GET_CURRENT_USER]({ commit }) {
      const response = await apollo.query({ query: getCurrentUser() });
      const { data: { currentUser: user } } = response;
      commit(SET_CURRENT_USER, { user });
      commit(SET_CLIENT, { client: user?.record?.client });
    },

    async [GET_FIELD_NAMES]({ commit }) {
      const response = await apollo.query({
        fetchPolicy: 'network-only',
        query: getFieldNames,
      });
      const { data: { searchInfo: { fieldName: fieldNames } } } = response;
      commit(SET_CUSTOM_FIELD_NAMES, { fieldNames });
    },

    async [SET_VIEWING_CLIENT]({ commit }, { client }) {
      commit(MUTATION_SET_VIEWING_CLIENT, { client });
    },

    async [REFRESH_FEATURES]({ state, commit }) {
      const response = await apollo.query({ query: getCurrentUserFeatures() });
      const { data: { currentUser: { enabledFeatures } } } = response;
      commit(SET_CURRENT_USER, { user: { ...state.currentUser, enabledFeatures } });
    },

    async [FETCH_MEASURE_SPECTRA]({ commit }, selectedMeasures = []) {
      commit(SET_IS_LOADING_SPECTRA, false);

      if (!selectedMeasures?.length) {
        return;
      }
      const ids = selectedMeasures.map(({ id }) => id);
      if (!ids?.length) {
        return;
      }
      commit(SET_IS_LOADING_SPECTRA, true);

      const { data: { measurements: { nodes = [] } = {} } = {} } = await apollo.query({
        query: getSelectedData(undefined, 'measurementsWithData'),
        variables: { filter: { ids } },
      }) || {};

      commit(
        SET_SPECTRA,
        nodes?.length
          ? mapArrayToObject(nodes, (node) => [node.id, node])
          : {},
      );

      commit(SET_IS_LOADING_SPECTRA, false);
    },

    async [SET_MEASURE_SELECTION]({ /* state, */ commit/*  dispatch */ }, selection) {
      await commit(SET_SELECTED_MEASURES, selection);
      // await dispatch(FETCH_MEASURE_SPECTRA, state.selectedMeasures);
    },

    // globally registered actions. Basically if the action does not "commit" anything to state
    // it is probably reusable in other stores.
    // (see https://v3.vuex.vuejs.org/guide/modules.html#register-global-action-in-namespaced-modules)
    GET_AWRI_RESULTS: {
      root: true,
      async handler(_, { measures = [] }) {
        const isAwriMeasure = ({ methodology }) => [
          Methodology.AWRI_ACID,
          Methodology.AWRI_BLEACH,
        ].includes(methodology);

        const ids = measures.filter(isAwriMeasure).map(({ id }) => id);

        if (!ids?.length) {
          return {
            errors: [],
            items: [],
          };
        }

        const response = await apollo.query({
          query: getAwriResults,
          variables: {
            measures: ids,
          },
        });

        const {
          data: {
            analyses: { awri: items = [] } = {},
            errors = [],
          } = {},
        } = response || {};

        return {
          errors,
          items,
        };
      },
    },

    GET_MEASURES_BY_ID: {
      root: true,
      async handler(_, { ids = [-1], ...filter }) {
        const result = await apollo.query({
          query: graphqlGetMeasuresByID(undefined, 'missingFieldsMeasurements'),
          variables: {
            filter: {
              ...filter,
              ids,
            },
          },
        });

        const { data: { measurements: { nodes } } } = result;
        return nodes;
      },
    },
  };
}
