<template>
  <v-main class="about fill-height">
    <v-container fluid>
      <v-card flat rounded
        class="fill-height">
        <v-img :src="images.wineBG" height="228"></v-img>
        <v-card-title>CloudSpec</v-card-title>
        <v-card-text>
          <v-img :src="images.logo" width="33vw"
            class="ml-4"
            style="float: right"
          />
          Introducing the CloudSpec<sup>TM </sup> – the groundbreaking
          spectrophotometer developed by New Zealand based company MaramaLabs.
          The CloudSpec can measure highly cloudy samples with unprecedented
          accuracy.</v-card-text>
        <v-card-text>
          <v-img :src="images.wineglasses" width="16vw"
            style="float: left"
            class="mr-4"
          />
          The CloudSpec is not only a regular UV-Vis instrument. It also
          eliminates the need for the multiple filtering and clarification
          steps typically required to test cloudy samples with UV-Vis and
          allows them to be tested as is – in a couple of minutes.
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import logo from '../../public/img/logo_stacked.svg';
import wineglasses from '../../public/img/wineglasses.jpg';
import wineBG from '../../public/img/winebg.jpg';

export default {
  name: 'VeritasAbout',
  computed: {
    images() {
      return {
        logo,
        wineglasses,
        wineBG,
      };
    },
  },
};
</script>
