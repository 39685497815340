import gql from 'graphql-tag';
import nodeFields from '@/graphql/queries/partials/node';
import error from './partials/error';
import cielabData from './partials/cielabData';
import benchmarkData from './partials/benchmarkData';
import measureForList from './partials/measureForList';
import wavelengthData from './partials/wavelengthData';
import measureData from './partials/measureData';

const defaultBenchmarkFields = `
  ${nodeFields}
  ${benchmarkData}
  ${error}
`;

const defaultMeasureFields = `
  ${nodeFields}
  ${measureForList}
  ${wavelengthData}
  ${cielabData}
`;

export const fullMeasureFields = `
  ${nodeFields}
  ${measureForList}
  ${measureData}
  ${wavelengthData}
  ${cielabData}
`;
export default ({
  benchmarkFields = defaultBenchmarkFields,
  measureFields: requestedMeasureFields = defaultMeasureFields,
  queryName = 'benchmarkWines',
} = {}) => gql`query ${queryName}(
  $filter: BenchmarkFilter,
  $first: Int,
  $offset: Int = 0,
  $sortBy: String,
  $sortDesc: Boolean = false
) {
  benchmarks(
    filter: $filter,
    paging: {
      first: $first,
      offset: $offset,
      sortBy: $sortBy,
      sortDesc: $sortDesc
    }
  ) {
    __typename

    totalCount

    pageInfo {
      # endCursor
      hasNextPage
      hasPreviousPage
      # startCursor
    }

    nodes {
      ${benchmarkFields}

      ...on BenchmarkWine {
        measures {
          ${requestedMeasureFields}
        }
      }
    }
  }
}`;
