/* eslint-disable no-unused-vars */
export default function createReportsRoutes(path = '/reports', { beforeEnter }) {
  return [{
    path,
    beforeEnter,
    props: {
      default: true,
      appbar: {
        showSearchToggle: false,
        showSearchBar: false,
      },
    },
    meta: {
      title: 'Reports',
    },
    components: {
      appbar: () => import('@/components/AppBar.vue'),
      appbar_extension: () => import('@/components/AppBarTabs.vue'),
      default: () => import('@/views/VeritasReports.vue'),
    },
    children: [
      {
        path: '',
        name: 'Reports',
        component: () => import('@/components/reports/ReportsList.vue'),
      },
      {
        path: ':report',
        name: 'Report',
        props: true,
        component: () => import('@/components/reports/ReportDispatcher.vue'),
      },
    ],
  }];
}
