/* eslint-disable max-classes-per-file */
import dayjs from 'dayjs';
import { nowAsDate } from '@/helpers/now';
import structuredClone from '@/helpers/structuredClone';

export class SavedItems {
  constructor(name, items, id, clientId, userId, {
    description = '', created, lastUsed, useCount = 0, isFavorite = false,
  } = {}) {
    this.name = name;
    this.items = structuredClone(items);
    this.id = id;
    this.clientId = clientId;
    this.userId = userId;
    this.description = description;
    this.created = created ? dayjs(created) : nowAsDate();
    this.lastUsed = lastUsed ? dayjs(lastUsed) : dayjs(0);
    this.useCount = useCount;
    this.isFavorite = isFavorite;
  }

  toJSON() {
    const {
      name, items, id, clientId, userId, description, created, lastUsed, useCount, isFavorite,
    } = this;

    return {
      name,
      items,
      id,
      clientId,
      userId,
      description,
      created: created.toISOString(),
      lastUsed: lastUsed.toISOString(),
      useCount,
      isFavorite,
    };
  }

  static fromJSON(jsObject) {
    return new SavedItems(
      jsObject.name,
      jsObject.items,
      jsObject.id,
      jsObject.clientId,
      jsObject.userId,
      jsObject,
    );
  }

  updateUsage() {
    this.useCount += 1;
    this.lastUsed = nowAsDate();
  }
}

export class SavedItemsStore {
  constructor(storage, subpath = 'savedItems') {
    this.storage = storage;
    this.subpath = subpath;
    this.items = [];
    this._load();
  }

  _save() {
    this.storage.update(this.subpath, this.items.map((s) => s.toJSON()));
  }

  _load() {
    this.items = this.storage.read(this.subpath).map(SavedItems.fromJSON);
  }

  _findIndexByName(name) {
    return this.items.findIndex(({ name: existing }) => existing === name);
  }

  _findIndexByHash(hash) {
    return this.items.findIndex(({ id: existing }) => existing === hash);
  }

  _delete(index) {
    if (index < 0) {
      return;
    }

    this.items.splice(index, 1);
    this._save();
  }

  add(name, itemIds, savedItemsId, clientId, userId, description) {
    this.items.push(new this(name, itemIds, savedItemsId, clientId, userId, { description }));
    this._save();
  }

  updateUsage(name) {
    const index = this._findIndexByName(name);

    if (index < 0) {
      return;
    }

    this.items[index].updateUsage();
    this._save();
  }

  deleteById(id) {
    this._delete(this._findIndexByHash(id));
  }

  deleteByName(name) {
    this._delete(this._findIndexByName(name));
  }

  get list() {
    // use natural sorting and ignore accents on things
    const collation = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

    const compareNames = (a, b) => collation.compare(a, b);

    return this.items.slice()
      .sort((a, b) => {
        // sort alphanum and then by usecount, most used->least used
        const cond = [compareNames(a.name, b.name), b.useCount - a.useCount];
        for (let i = 0; i < cond.length; i += 1) {
          if (cond[i] !== 0) {
            return cond[i];
          }
        }
        return 0;
      });
  }
}
