export default `
  ... on Measure {
    id
    externalID

    analyses {
      wavelengths {
        entries {
          key
          value
        }
      }
    }
  }
`;
