import gql from 'graphql-tag';

const defaultFields = `
    error {
        message
        code
    }
    enabledFeatures
`;

export default (fields = defaultFields) => gql`query currentUserFeatures {
    currentUser {
        ${fields}
    }
}`;
