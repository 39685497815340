import LocalStorage from '@/modules/LocalStorage';
import { SavedUserWinesStore } from '@/modules/SavedUserWines';

export default function createState() {
  const localStore = new LocalStorage('maramaWines', { savedUserWines: [] });
  const savedUserWines = new SavedUserWinesStore(localStore, 'savedUserWines');

  return {
    maramaMarketWinesSelectionFilter: {
      first: -1, // show all by default
      offset: 0,
      search: '',
      sortBy: 'priceMax',
      sortDesc: false,
    },
    isLoadingMarketWines: false,
    maramaMarketWines: [],
    clientMarketWines: [],
    loadedUserWines: [],
    savedUserWines,
    selectedMeasures: [],
    totalMaramaMarketWines: 0, // total number in server-side database
    viewClient: null,
  };
}
