/* eslint-disable max-classes-per-file */
import { SavedItems, SavedItemsStore } from './SavedItems';

export class SavedUserWines extends SavedItems {
  static fromJSON(jsObject) {
    return new SavedUserWines(
      jsObject.name,
      jsObject.items,
      jsObject.id,
      jsObject.clientId,
      jsObject.userId,
      jsObject,
    );
  }

  updateItems(items) {
    this.items = items;
  }

  updateDescription(description) {
    this.description = description;
  }

  removeItem(item) {
    const itemToRemove = this.items.indexOf(item);
    this.items.splice(itemToRemove, 1);
  }
}

export class SavedUserWinesStore extends SavedItemsStore {
  _load() {
    this.items = this.storage.read(this.subpath).map(SavedUserWines.fromJSON);
  }

  add(name, measureIds, savedUserWinesId, clientId, userId, description) {
    const newUserWine = new SavedUserWines(
      name,
      measureIds,
      savedUserWinesId,
      clientId,
      userId,
      { description },
    );
    const index = this._findIndexByHash(newUserWine.id);

    if (index < 0) {
      this.items.push(newUserWine);
    } else {
      this.items[index].updateItems(measureIds);
      this.items[index].updateDescription(description);
    }

    this._save();
  }

  deleteAll() {
    this.items = [];
    this._save();
  }
}
