export default `
  ...on BenchmarkWine{
    id
    brand
    grape
    image
    name
    priceMax
    priceMin
    country
    region
    tags
    winery
    year
  }
`;
