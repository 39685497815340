<template>
  <v-combobox
    :delimiters="[' ', ';', ',']"
    :items="comboboxItems"
    :label="label"
    :loading="$apollo.loading"
    :menu-props="{ left: true, closeOnContentClick: true }"
    :search-input.sync="currentSearch"
    :value="value"
    @input="onInput"
    auto-select-first
    chips
    clearable
    deletable-chips
    hide-selected
    item-text="tag"
    multiple
    no-filter
    small-chips
    v-bind="$attrs"
  >
    <template #no-data>
       <base-list-item :title="currentSearch">
        <template #subtitle>
          No label exists with this name
        </template>
      </base-list-item>
    </template>

    <template #item="{item}">
      <span :title="item">{{ item }}</span>
    </template>
  </v-combobox>
</template>

<script>
import apiTags from '@/mixins/apiTags';
import buildRegExpFromString from '@/helpers/buildRegExpFromString';

export default {
  name: 'TagsField',
  emits: ['input'],
  mixins: [apiTags],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Labels',
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentSearch: null,
    localTags: [],
  }),
  computed: {
    serverTags() {
      return this.tags.nodes.map(({ tag }) => tag);
    },
    availableTags() {
      const { currentSearch } = this;
      const sortTagsList = currentSearch
        ? (a, b) => {
          const lengthDiffA = Math.abs(currentSearch.length - a.length);
          const lengthDiffB = Math.abs(currentSearch.length - b.length);

          if (lengthDiffA !== lengthDiffB) {
            return lengthDiffA - lengthDiffB;
          }
          return a.localeCompare(b);
        }
        : undefined;

      return [
        ...this.serverTags,
        ...this.localTags,
      ].sort(sortTagsList);
    },
    comboboxItems() {
      const matches = this.filterTagsThatMatch(this.currentSearch);
      return this.getMatchingLabelHeaders(matches).concat(matches);
    },
  },
  methods: {
    isUniqueTag(tag) {
      return !this.value.includes(tag);
    },
    getMatchingLabelHeaders(matches) {
      if (!this.currentSearch) {
        return [{ header: 'Existing labels' }];
      }

      const isPerfectMatch = matches.length === 1
        && String(matches[0]).toLowerCase() === String(this.currentSearch).toLowerCase();

      if (isPerfectMatch && this.editable) {
        return [(this.isUniqueTag(this.currentSearch)
          ? { header: 'Use existing label or type a new one' }
          : { header: 'Label already selected' })];
      }

      const hasUniqueMatches = matches.filter((match) => this.isUniqueTag(match)).length > 0;

      if (this.editable) {
        return [
          { header: 'Create a new label' },
          this.currentSearch,
          (hasUniqueMatches ? { header: 'or pick an existing label' } : null),
        ];
      }

      return [];
    },
    filterTagsThatMatch(queryText) {
      return this.availableTags.filter((tag) => this.isTagMatching(tag, queryText, String(tag)));
    },
    isTagMatching(tag, queryText, tagText) {
      if (!queryText || tag.header) {
        return true;
      }

      return buildRegExpFromString(queryText).test(tagText);
    },
    onInput(value) {
      const nonEmptyTags = value.filter((x) => Boolean(String(x).trim()));
      this.$emit('input', nonEmptyTags);
      this.currentSearch = null;
    },
  },
};
</script>
