import { getInstance } from '@/plugins/auth0';

// eslint-disable-next-line consistent-return
export default (to, from, next) => {
  const authService = getInstance();

  if (!authService) {
    throw new Error('Unable to set up authentication service');
  }

  // eslint-disable-next-line consistent-return
  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  // eslint-disable-next-line consistent-return
  authService.$watch('loading', (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
