import About from '@/views/VeritasAbout.vue';

export default function createAboutRoutes(path = '/'/* , options={} */) {
  return [{
    path,
    name: 'About',
    components: {
      default: About,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      appbar: () => import('../../components/AppBarGuest.vue'),
    },
    props: {
      appbar(/* route */) {
        return {
          showSearchToggle: false,
        };
      },
    },
  }];
}
