import AppFeatures from '../constants/appFeatures';
import doesObjectHaveField from './doesObjectHaveField';

export function doesFeatureExist(name) {
  return Object.values(AppFeatures).indexOf(name) >= 0;
}

export { AppFeatures };

export default (featureName, featureMap = {}) => {
  if (!doesFeatureExist(featureName)) {
    throw new Error(`Undefined feature ${featureName} is not recognized`);
  }
  return doesObjectHaveField(featureMap, featureName);
};
