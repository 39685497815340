import Vue from 'vue';

import createRouter from '@/router';
import getStoreInstance from '@/store/index';

import vuetify from '@/plugins/vuetify';

import { registerFilters, registerGlobalComponents, registerPlugins } from '@/app/setupVue';
import { initSentry } from '@/sentry';

import App from '@/App.vue';
import getApolloProviderInstance from './apolloSetup';

import '@/helpers/calcVhSize';
import '@/styles/global.scss';
import 'vue-file-agent/dist/vue-file-agent.css';
import './registerServiceWorker';
import getApolloClientInstance from './apolloClient';

/**
 * The init function is the entry point for the application.  It is responsible
 * for initializing all of the global state and services that are used by other
 * parts of the app.  This includes:
 * - Vue plugins (Vuex, Apollo, Vuetify)
 * - Global components (e.g., ErrorBoundary) and filters (e.g., formatDateTime)
 * that can be used in any component template without importing them first
 *
 * @return {Promise} so we can use it to wait for the app to be ready.
 */
async function init() {
  Vue.config.productionTip = false;

  let store;
  const clientInfo = {
    getCurrentClientID() {
      return store.state.generalStore.viewClient?.id;
    },
  };

  const apolloClient = getApolloClientInstance({ clientInfo });
  store = getStoreInstance({ apollo: apolloClient });
  const router = createRouter({ store });

  initSentry({ Vue, router });

  registerGlobalComponents(Vue);
  registerPlugins(Vue, router);
  registerFilters(Vue);

  Vue.config.errorHandler = (err, vm, info) => {
    try {
      console.error('[Vue]', err?.message || info, vm?.$options?.name || vm?.$el, err);
    } catch {
      // swallow any errors here to prevent infinite loop in case we couldn't log error!
    }
  };

  const apolloProvider = getApolloProviderInstance({ apolloClient });

  const app = new Vue({
    router,
    store,
    apolloProvider,
    vuetify,
    render: (h) => h(App),
  });

  return app;
}

init()
  .then((app) => app.$mount('#app'))
  .catch((error) => {
    // show an emergency error message in the index.html page as we couldn't
    // make things work at all!

    const element = document.querySelector('#app');
    if (!element) {
      console.error('Fatal error', error);
      return;
    }

    element.textContent = error.message;
    throw error;
  });
