/* eslint-disable no-plusplus */

/**
 * Set a value in an object, recursively.
 * Path is a dot-separated path, e.g.
 *   'settings.default.dataPath'
 *
 * @param  {Object} target Target object
 * @param  {string} key    Path to key
 * @param  {mixed} value   new value
 * @return {Object}        returns `target`
 */
export function dotSet(target, path, value) {
  let root = target;
  let key;
  const pathParts = path.split('.');

  let i;
  for (i = 0; i < pathParts.length - 1; i++) {
    key = pathParts[i];

    if (!root[key]) {
      root[key] = {};
    }

    root = root[key];
  }
  key = pathParts[i];

  root[key] = value;
  return root;
}

/**
 * Get a value from an object, recursively.
 * Path is a dot-separated path, e.g.
 *   'settings.default.dataPath'
 *
 * @param  {Object} target Target object
 * @param  {string} key    Path to key
 * @return {Object}        returns `target`
 */
export function dotGet(target, path) {
  let root = target;
  let key;
  const pathParts = path.split('.');

  let i;
  for (i = 0; i < pathParts.length - 1; i++) {
    key = pathParts[i];

    if (!root[key] || !(root[key] instanceof Object)) {
      return undefined;
    }

    root = root[key];
  }
  key = pathParts[i];
  return root[key];
}
