/**
 * Function used to parse result of user callback for both
 * objectMap and mapArrayToObject
 *
 * @param {Array|Object|ANY} result -
 * @param {string|number} key -
 * @return {Object} { [key]: value } or undefined
 */
function parseResult(result, key) {
  if (result instanceof Array) {
    if (!result.length) {
      return {};
    }
    const [newKey, newValue] = result;
    // eslint-disable-next-line no-param-reassign
    return { [newKey]: newValue };
  } if (typeof result === 'object') {
    // eslint-disable-next-line no-param-reassign
    return result;
  }
  // eslint-disable-next-line no-param-reassign
  return { [key]: result };
}

/**
 * Map an object like Array.map
 * The callback is passed to reduce as follows:
 *
 * ```js
 *   callback(key, value, index, previousOutput) : result
 * ```
 *
 * The callback should return:
 *    [newKey, newValue] for the current key
 *   OR
 *    { ...merge } to be merged into output object
 *   OR
 *    new value to replace `value`
 *
 * **Example usage**
 *
 * ```js
 *    objectMap({ a: 1, b: 2 }, () => 3) == {a: 3, b: 3}
 *    objectMap({ a: 1, b: 2 }, (key) =>  [key+'1', 3]) == {a1: 2, b1: 3}
 *    objectMap({ a: 1, b: 2 },
 *      (key, value, index) => ({[key]: value, [key+index]: true })
 *    ) === { a:1, b:2, a0: true, b1: true }
 * ```
 *
 * If options.failAll is not set, then any error thrown from callback will cause
 *  `output[key] => Error`
 *
 *  If it is true (the default), then any error causes the whole objectMap call to throw
 *
 * @param {Object} obj - Object to map
 * @param {(key: string, value: any, index: number, previousOutput: any) => [
 *  key: string, value: any
 * ]|{}|any} callback
 * @param {{failAll?: boolean}?} options
 */
export default function objectMap(obj, callback, { failAll = true } = {}) {
  let caught = null;

  if (!(callback instanceof Function)) {
    throw new Error('objectMap callback is not a function');
  }

  const result = Object.keys(obj || {}).reduce((akk, k, idx, arr) => {
    try {
      const itemResult = callback(k, obj[k], idx, akk, arr);
      if (typeof itemResult !== 'undefined') {
        Object.assign(akk, parseResult(itemResult, k));
      }
    } catch (error) {
      if (!caught) {
        caught = error;
      }

      if (!failAll) {
        // eslint-disable-next-line no-param-reassign
        akk[k] = error;
      } else {
        throw error;
      }
    }
    return akk;
  }, Object.create(obj?.constructor?.prototype || null));

  if (failAll && caught !== null) {
    throw caught;
  }

  return result;
}

/**
 * Map an array into an object, e.g.
 *
 * @param {any[]} array
 * @param {(entry: any, idx: number, akk: object, arr: array) => [key: string, value: any]} callback
 * @param {{ failAll?: boolean }?} options
 * @return {{[key: string]: any }}
 *
 * **Example usage**
 *
 * ```js
 * mapArrayToObject([ 'one', 'two', 'three' ], x => [x, true])
 *  => { one: true, two: true, three: true }
 * mapArrayToObject([ 'one', 'two', 'three' ], x => ({[x]: true]}))
 *  => { one: true, two: true, three: true }
 * mapArrayToObject([ 'one', 'two', 'three' ], x => true)
 *  => { one: true, two: true, three: true }
 * mapArrayToObject([ 'one', 'two', 'three' ], true)
 *  => { one: true, two: true, three: true }
 * mapArrayToObject([ 'one', 'two', 'three' ], false)
 *  => { one: false, two: false, three: false }
 * mapArrayToObject([ 'one', 'two', 'three' ], undefined)
 *  => { }
 * mapArrayToObject([ 'one', 'two', 'three' ], (x) => [x, x === 'one' ? undefined  : x])
 *  => { two: 'two', three: 'three' }
 * ```
 */
export function mapArrayToObject(array, callback, { failAll = true } = {}) {
  if (!(callback instanceof Function)) {
    const constValue = callback;
    // eslint-disable-next-line no-param-reassign
    callback = (key) => [key, constValue];
  }

  if (!(array instanceof Array)) {
    return {};
  }

  let caught = null;

  const result = array.reduce((akk, item, idx, arr) => {
    try {
      const itemResult = callback(item, idx, akk, arr);
      if (typeof itemResult !== 'undefined') {
        Object.assign(akk, parseResult(itemResult, String(item)));
      }
    } catch (error) {
      if (!caught) {
        caught = error;
      }

      if (!failAll) {
        // eslint-disable-next-line no-param-reassign
        akk[idx] = error;
      } else {
        throw error;
      }
    }
    return akk;
  }, Object.create(null));

  if (failAll && caught) {
    throw caught;
  }

  return result;
}

/**
 * Map an object into an array
 *
 * @param {Object} object
 * @param {(key: string, value: any, index: number) => any} callback
 */
export function mapObjectToArray(object, callback) {
  if (!(callback instanceof Function)) {
    // eslint-disable-next-line no-param-reassign
    callback = (key, value, index) => ({ key, value, index });
  }
  return Object.keys(object || {}).map((key, index) => callback(key, object[key], index, object));
}

/**
 * Iterate the keys and values of the given object.
 * Callback has the similar format as for `objectMap`, but return value is ignored.
 *
 * @param {object} obj
 * @param {(key: string, value: any, index: number) => void} callback
*/
export function forEachKey(obj, callback) {
  Object.keys(obj || {}).forEach((k, idx) => callback(k, obj[k], idx));
}

export function mapArrayByObjectField(list, fieldName) {
  return mapArrayToObject(list, (item) => [item[fieldName], item]);
}
