import { getMethodologyLabel } from '@/constants/methodology';
import { getProcessStageMetadata } from '@/constants/processStages';

/**
 * Return a human-readable description of a search filter
 */
export default function getSearchDescription(search) {
  const {
    batch,
    fieldName,
    fieldValue,
    grape,
    methodology,
    name,
    processStage,
    region,
    tags,
    vessel,
    vineyard,
    year,
    ids,
  } = search;

  const methodologyDesc = getMethodologyLabel(methodology);
  const processStageDesc = getProcessStageMetadata(processStage).label;
  const tagDesc = tags?.length ? [`tagged with '${tags.join("', '")}'`] : [];

  const fieldDesc = fieldName
    ? [`where ${fieldName} is ${fieldValue}`]
    : [];

  const idDesc = ids ? ['User Wines'] : [];

  return [
    name,
    year,
    grape,
    batch,
    vessel,
    processStageDesc,
    methodologyDesc,
    region,
    vineyard,
    idDesc,
    ...tagDesc,
    ...fieldDesc,
  ]
    .filter((x) => Boolean(x))
    .join(' ');
}
