import { mapArrayToObject } from '@/helpers/objectMap';
import getBenchmarkWines from '@/graphql/queries/getBenchmarkWines';
import { escapeRegexOperators } from '@/helpers/buildRegExpFromString';
import {
  FETCH_MARAMA_MARKET_WINES,
  UPDATE_LOADED_USER_WINES,
  SAVE_USER_WINE,
  DELETE_USER_WINES,
  GET_USER_WINE_BY_MEASURE_ID,
  MIGRATE_USER_WINES,
} from '@/store/actionNames';

import {
  SET_IS_LOADING_MARKET_WINES,
  SET_MARAMA_MARKET_WINES,
  SET_TOTAL_MARAMA_MARKET_WINES,
  ADD_SAVED_USER_WINE,
  DELETE_SAVED_USER_WINE,
  DELETE_SAVED_USER_WINE_BY_NAME,
  SET_LOADED_USER_WINES,
} from '@/store/mutationNames';
import { getFilteredList } from '@/graphql/queries';
import hydrateMeasure from '@/helpers/hydrateMeasure';
import digestItemIds from '@/helpers/digestItemIds';

export default function createActions({ apollo }) {
  return {

    async [FETCH_MARAMA_MARKET_WINES](
      { state, commit },
      params,
    ) {
      const { search, ...paging } = params || state.maramaMarketWinesSelectionFilter;

      const escapedSearch = escapeRegexOperators(search ? String(search) : '');

      const filter = mapArrayToObject(
        ['winery', 'brand', 'grape', 'name', 'region', 'country'],
        () => escapedSearch,
      );

      // cancel observable queries named 'benchmarkWines' that have not returned
      if (state.isLoadingMarketWines) {
        let oq = null;
        Array.from(apollo.queryManager.queries.entries()).forEach(([queryId, queryInfo]) => {
          oq = queryInfo.observableQuery;
          if (oq && oq.queryName === 'benchmarkWines') {
            apollo.queryManager.stopQueryNoBroadcast(queryId); // this removes the query too.
          }
        });
      }

      commit(SET_IS_LOADING_MARKET_WINES, true);

      const benchmarkWinesQueryObserver = apollo.watchQuery({
        query: getBenchmarkWines(),
        variables: { filter, ...paging },
      });
      benchmarkWinesQueryObserver.subscribe({
        next: ({ data }) => {
          const { benchmarks: { totalCount = 0, nodes = [] } = {} } = data || {};

          commit(SET_IS_LOADING_MARKET_WINES, false);
          // note: this mutation hydrates measures
          commit(SET_MARAMA_MARKET_WINES, nodes);
          commit(SET_TOTAL_MARAMA_MARKET_WINES, totalCount);

          benchmarkWinesQueryObserver.tearDownQuery();
        },
        error: (e) => console.error(e),
      });
    },
    async [SAVE_USER_WINE]({ commit, dispatch, rootState }, selection) {
      const { items } = selection;
      const measureIds = items.map((m) => m.id);
      const savedUserWinesId = await digestItemIds(measureIds);

      // do not allow mixing of client Ids - show only relevant client user wines
      const currentClientId = rootState.generalStore.viewClient?.id
        || rootState.generalStore.client.id;

      const userId = rootState.generalStore.currentUser.record.externalID;

      await commit(ADD_SAVED_USER_WINE, {
        params: {
          selection: {
            ...selection,
            items: measureIds,
          },
          savedUserWinesId,
          currentClientId,
          userId,
        },
      });
      await dispatch(UPDATE_LOADED_USER_WINES);
    },
    async [DELETE_USER_WINES]({ commit, dispatch }, userWines) {
      userWines.forEach((userWine) => {
        commit(DELETE_SAVED_USER_WINE, userWine.id);
      });

      await dispatch(UPDATE_LOADED_USER_WINES);
    },
    async [GET_USER_WINE_BY_MEASURE_ID]({ state }, measureId) {
      return state.loadedUserWines.find(
        (userWine) => userWine.items.map((item) => item.id).includes(measureId),
      );
    },
    async [MIGRATE_USER_WINES]({
      state, commit, rootState,
    }) {
      // user wines without attr 'id' or have ids that are 'undefined' need migrating
      const userWinesNeedingMigration = state.savedUserWines.list.filter(
        (userWine) => userWine.id === undefined,
      );
      const role = rootState.generalStore.currentUser.permissions[0];
      const userId = rootState.generalStore.currentUser.record.id;
      const currentClientId = rootState.generalStore.client.id;

      // user wines lacking id/clientId/userId created by us can just be removed
      if (role === 'admin') {
        userWinesNeedingMigration.forEach((userWine) => {
          commit(DELETE_SAVED_USER_WINE_BY_NAME, userWine.name);
        });
        return;
      }

      // migrate - create new userWines with old userWines attrs and replace in localStorage
      userWinesNeedingMigration.forEach(async (userWine) => {
        const savedUserWinesId = await digestItemIds(userWine.items);

        commit(DELETE_SAVED_USER_WINE_BY_NAME, userWine.name);
        commit(ADD_SAVED_USER_WINE, {
          params: {
            selection: userWine, savedUserWinesId, currentClientId, userId,
          },
        });
      });
    },
    async [UPDATE_LOADED_USER_WINES]({
      state, commit, dispatch, rootState,
    }) {
      await dispatch(MIGRATE_USER_WINES);
      // do not allow mixing of client Ids - show only relevant client user wines
      const currentClientId = rootState.generalStore.viewClient?.id
        || rootState.generalStore.client.id;

      const savedUserWines = state.savedUserWines.list
        .filter((userWine) => userWine.clientId === currentClientId);

      const savedUserWineIds = savedUserWines
        .map((userWine) => userWine.id);

      const currentUserWines = state.loadedUserWines;
      const currentUserWineIds = currentUserWines
        .map((userWine) => userWine.id);

      const userWinesToAdd = savedUserWines.filter(
        ({ id }) => !currentUserWineIds.includes(id),
      );

      const newUserWines = currentUserWines.filter(
        ({ id }) => savedUserWineIds.includes(id),
      );

      if (userWinesToAdd.length) {
        const filter = { status: 'saved' };
        const ids = userWinesToAdd
          .map((userWine) => userWine.items)
          .filter((item) => item !== null || item !== undefined)
          .flat();

        const measuresToAdd = await apollo.query({
          query: getFilteredList(),
          variables: { filter: { ...filter, ids } },
          update({ measurements: { nodes: measures } }) {
            return measures.map((m) => hydrateMeasure(m));
          },
        });

        const fullMeasures = measuresToAdd.data.measurements.nodes;
        userWinesToAdd.forEach((userWine) => {
          const updatedItems = userWine.items.map(
            (item) => fullMeasures.find((measure) => measure.id === item),
          );
          const updatedUserWine = {
            ...userWine,
            items: updatedItems,
          };
          newUserWines.push(updatedUserWine);
        });
      }
      await commit(SET_LOADED_USER_WINES, newUserWines);
    },
  };
}
