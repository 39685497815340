import fields from './fields';

export default `
  ... on Measure {
    batch
    dilution
    externalID
    processStageDay
    grape
    measuredOn
    methodology
    name
    processStage
    region
    status
    tags
    vessel
    vineyard
    year


    ${fields}
  }
`;
