import Vue from 'vue';

import { AppFeatures } from '@/helpers/hasFeature';
import setTitle from '@/helpers/setTitle';
import { getBaseURLFromEnv } from '@/app/parseEnv';
import authGuard from './authGuard';
import patchVueRouter from './patchVueRouter';
import createAboutRoutes from './routes/about';
import createDashboardRoutes from './routes/dashboard';
import createReportsRoutes from './routes/reports';
import createMarketRoutes from './routes/market';
import combinedGuard from './combinedGuard';
import createFeatureGuard from './createFeatureGuard';

export default function createRouter({ store }) {
  /* Reused by routes with different paths */
  const viewMeasureRoute = {
    beforeEnter: authGuard,
    meta: {
      isTitleManaged: true,
    },
    props: {
      appbar(route) {
        return {
          title: route.params.name,
          showSearchToggle: false,
        };
      },
      default: true,
    },
    components: {
      appbar: () => import('../components/AppBar.vue'),
      appbar_extension: () => import('../components/AppBarBack.vue'),
      default: () => import('../views/MeasureView.vue'),
    },
  };

  const routes = [
    ...createAboutRoutes('/'),
    ...createDashboardRoutes('/dashboard', { beforeEnter: authGuard }),
    ...createMarketRoutes(
      '/market',
      {
        beforeEnter: combinedGuard(
          authGuard,
          createFeatureGuard(store, AppFeatures.MARKET_VIEW),
        ),
      },
    ),
    ...createReportsRoutes(
      '/reports',
      {
        beforeEnter: combinedGuard(
          authGuard,
          createFeatureGuard(store, AppFeatures.REPORTING),
        ),
      },
    ),
    {
      path: '/profile',
      name: 'Profile',
      beforeEnter: authGuard,
      components: {
        appbar: () => import('../components/AppBar.vue'),
        appbar_extension: () => import('../components/AppBarBack.vue'),
        default: () => import('../views/VeritasProfile.vue'),
      },
      props: {
        appbar(/* route */) {
          return {
            showSearchToggle: false,
          };
        },
      },
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      beforeEnter: authGuard,
      components: {
        appbar: () => import('../components/AppBar.vue'),
        appbar_extension: () => import('../components/AppBarBack.vue'),
        default: () => import('../views/PrivacyPolicyView.vue'),
      },
      props: {
        appbar(/* route */) {
          return {
            showSearchToggle: false,
          };
        },
      },
    },
    {
      path: '/order-supplies',
      name: 'SupplyOrderForm',
      components: {
        appbar: () => import('../components/AppBar.vue'),
        appbar_extension: () => import('../components/AppBarBackIfLoggedIn.vue'),
        default: () => import('../views/SupplyOrderForm.vue'),
      },
      props: {
        appbar(/* route */) {
          return {
            showSearchToggle: false,
          };
        },
      },
    },
    {
      path: '/measure/:id',
      name: 'ViewMeasureNoName',
      ...viewMeasureRoute,
    },
    {
      // optional path parts are ignored, it's just for nicer URLs
      path: '/measure/:name?/:id',
      name: 'ViewMeasure',
      ...viewMeasureRoute,
    },
    {
      // optional path parts are ignored, it's just for nicer URLs
      path: '/edit/:name?/:id',
      name: 'EditMeasure',
      ...viewMeasureRoute,
      props: {
        ...viewMeasureRoute.props,
        default(route) {
          return {
            id: route.params.id,
            edit: true,
          };
        },
      },
    },
    {
      name: 'Login',
      path: '/login',
      components: {
        appbar: () => import('../components/AppBar.vue'),
        default: () => import('../views/VeritasLogin.vue'),
      },
    },
    {
      path: '*',
      name: '404',
      components: {
        appbar: () => import('../components/AppBar.vue'),
        default: () => import('../views/VeritasFourOhFour.vue'),
      },
    },
  ];

  const VueRouter = patchVueRouter();
  const router = new VueRouter({
    mode: 'history',
    base: getBaseURLFromEnv(),
    routes,
  });

  router.afterEach((to/* , from */) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
      if (to.meta?.isTitleManaged) {
        // for routes set their own <title />
        return;
      }
      setTitle(to?.meta?.title || to?.name || to?.path, { isRoot: to.path === '/' });
    });
  });

  return router;
}
