import Vue from 'vue';
import Vuex from 'vuex';

import isDev from '@/helpers/isDev';
import createGeneralStore from './modules/generalStore';
import createMarketWinesStore from './modules/market_wines_store';

let store;

export default function getStoreInstance({ apollo }) {
  if (!store) {
    Vue.use(Vuex);
    const generalStore = createGeneralStore(apollo);
    const marketWinesStore = createMarketWinesStore(apollo);

    store = new Vuex.Store({
      modules: {
        generalStore,
        marketWinesStore,
      },
      strict: isDev,
    });
  }

  return store;
}
