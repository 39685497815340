export default function createGetters() {
  return {
    selectedMarketWineMeasuresMarama(state, getters, rootState) {
      return rootState.generalStore.selectedMeasures.filter((m) => m.benchmark);
    },
    selectedNonMarketWineMeasuresMarama(state, getters, rootState) {
      return rootState.generalStore.selectedMeasures.filter((m) => !m.benchmark);
    },
    // selectedNonMarketWineMeasuresClient(state, { selectedMeasures }, rootState) {
    //   return rootState.generalStore.selectedMeasures.filter((m) => !m.benchmark);
    // },
    savedUserWineMeasureIds(state) {
      return state.loadedUserWines
        .map((userWine) => userWine.items)
        .flat()
        .map((item) => item.id);
    },
    hasSavedUserWines(state) {
      return state.loadedUserWines.length > 0;
    },
  };
}
