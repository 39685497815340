import gql from 'graphql-tag';
import error from './partials/error';

export default gql`query tags(
  $filter: String,
  $first: Int
) {
  tags(
    filter: $filter
    paging: { first: $first }
  ) {
    ${'' && 'totalCount'}
    nodes {
      ${error}
      ... on Tag {
        tag
      }
    }
  }
}
`;
